import { Injectable } from '@angular/core';

@Injectable()
export class ThemeOptions {
    sidebarHover = false;
    toggleSidebar = false;
    toggleSidebarMobile = false;
    toggleHeaderMobile = false;
    toggleThemeOptions = false;
    toggleDrawer = false;
    toggleFixedFooter = false;
}
