// "outputPath": "dist/architectui-angular-pro",
export const environment = {
	production: false,
	version: '1.0.0',
	app: {
		paymentUrls: {
			successUrl: "https://staging-salubrizare-mob.egovernment.ro/success-bt-payment",
			errorUrl: "https://staging-salubrizare-mob.egovernment.ro/fail-bt-payment"
		}
	},
	config: {
		fromTokenValid: 'https://staging-salubrizare-mob.egovernment.ro/api/auth/login-cnp',
		tokenKey: 'esalubl-web-app-token',
		userKey: 'esalubl-web-app-user',
		storageKey: "esalubl-web-app",
		copyrightText: "Copyright © eSalubrizare Sector 5 - 2022 - 2023",
		defaultLNG: 'ro',
		emailRegex: "^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$",
		codeSMSRegex: /^[0-9]+$/,
		phoneRegex: '[0-9]{6,20}',
		cnpRegex: '[0-9]{13,13}',
		validatorsAccrossApp: {
			minPassword: 8,
			maxPassword: 50,
			emailMaxLength: 50,
			minStringLength: 2,
			maxStringLength: 60,
			minSmsCodeLength: 1,
			maxSmsCodeLength: 1
		},
		customNotifications: {
			icons: {
				success: "success",
				error: "error",
				info: "info",
				warning: "warning"
			},
			headers: {
				success: "Succes",
				error: "Eroare",
				successForm: "Felicitări!"
			},
			generalMessages: {
				error: "Ne pare rău, dar am întâmpinat o problemă. Vă rugăm să reîncercați. Dacă problema persistă, vă rugăm să ne contactați prin butonul de suport IT.",
				fileErrorMsg: "Ne pare rău, dar fișierul selectat nu a putut fi încărcat. Vă rugăm să reîncercați!"
			}
		}
	},
	interop: {
		basePath: "https://staging-salubrizare-mob.egovernment.ro",
		user: {
			loginUrl: '/api/auth/login-cnp',
			refreshLogin: '/api/refresh-login',
			resendCode: '/api/cetatean-resend-code',
			confirmCode: '/api/cetatean-confirm-code',
			checkEmailPasswordReset: '/api/auth/password/reset-password-cnp',
			resendCodePasswordReset: '/api/auth/password/reset-password-resend-code',
			verifyCodePasswordReset: "/api/auth/password/reset-verify-code",
			updatePasswordReset: "/api/auth/password/reset-password-update",
			fileUploadRegister: '/api/upload-file-register',
			fileUploadRegisterBase64: '/api/upload-file-register-base64',
			registerAccount: '/api/cetatean-register',
			registerAccountInrolat: '/api/cetatean-register-inrolat',
			findDetails: '/api/cetatean/find-details',
			updatePasswordAccount: '/api/cetatean/password',
			updateInfoAccount: '/api/cetatean/profile-update',
			addressSave: '/api/cetatean/address/save',
			addressList: '/api/cetatean/address/list',
			addressDelete: '/api/cetatean/address/delete/',
			checkPinRegister: "/api/auth/check-pin-register",
			suportOnline: '/api/suport-online',
			checkCNPStatusAccount: '/api/cetatean-register-check-cnp',
			resetPINCodeInrolatAccount: '/api/cetatean-reset-pin-code-inrolare',
			faqs: "/api/getFAQPageSlug/"
		},
		api: {
			payments: {
				requestPayment: '/api/mobile-payment-request-v2',
				getTax: '/api/get-taxes-cetatean-v2',
				processPaymentsRecurente: '/api/process-payments-recurente/',
				getCards: '/api/get-cards',
				removeCard: '/api/remove-card/'
			},
			solicitari: {
				saveSolicitareContract: '/api/save-solicitare-contract',
				solicitariList: '/api/solicitarile-mele',
				solicitariView: '/api/view-solicitare/',
				saveMessageSolicitare: '/api/solicitare-save-message/',
				downloadDocumentSolicitare: '/api/document/',
			},
			sesizari: {
				list: '/api/sesizari-v2',
				one: '/api/sesizare/',
				saveMessage: '/api/sesizare-chat-v2/',
				downloadFile: '/api/sesizare-download-file/',
				downloadFilePDF: '/api/sesizare-download-file-pdf/'
			},
			contracte: {
				list: '/api/get-contracte-info',
				facturi: '/api/get-facturi-info',
				suportOnline: '/api/account/suport-online'
			},
			locatii: {
				list: '/api/locatie-list',
				one: '/api/locatie/'
			},
			utile: {
				list: '/api/utile-list',
				one: '/api/utile/'
			},
			notificari: {
				list: '/api/notificari-list',
				one: '/api/notificare-view/',
				markAsRead: '/api/notificari-read',
				count: '/api/mesaje-count'
			}
		}
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
