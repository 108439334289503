import { Component, OnInit, Inject, ViewChild, TemplateRef, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';

import { ErrorStateMatcher } from '@angular/material/core';

import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';

import { environment } from '../../../environments/environment'
import { NotificationService } from '../../general/notification.service'

import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';

import { faLightbulb } from '@fortawesome/free-solid-svg-icons';

import * as _moment from 'moment';
const moment = _moment;

import { cloneDeep, random } from 'lodash-es';
import {
	GlobalConfig,
	ToastrService,
	ToastContainerDirective,
	ToastNoAnimation,
} from 'ngx-toastr';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const isSubmitted = form && form.submitted;
		return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
	}
}

import { AuthService } from '../auth.service'


@Component({
	selector: 'app-faqs',
	templateUrl: './faqs.component.html',
	styleUrls: ['./faqs.component.sass']
})
export class FaqsComponent implements OnInit {
	icon = 'pe-7s-diamond icon-gradient bg-warm-flame';
	lightBulb = faLightbulb
	@Input() FAQSection: string;

	options: GlobalConfig;

	// general error
	errorTitle: string = environment.config.customNotifications.headers.error
	errorIcon: string = environment.config.customNotifications.icons.error
	errorType: string = environment.config.customNotifications.icons.error
	// general success alert
	successTitle: string = environment.config.customNotifications.headers.success

	successIcon: string = environment.config.customNotifications.icons.success
	successType: string = environment.config.customNotifications.icons.success

	@ViewChild('ngxLoading') ngxLoadingComponent!: NgxLoadingComponent;
	@ViewChild('customLoadingTemplate') customLoadingTemplate!: TemplateRef<any>;
	public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
	public loading = false;
	public loadingTemplate!: TemplateRef<any> | null;
	matcher = new MyErrorStateMatcher();

	loaded: number = 0
	FAQList: any = []

	constructor(
		private authService: AuthService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<FaqsComponent>,
		private notificationService: NotificationService,
		public toastr: ToastrService,
	) {
		dialogRef.disableClose = true;
		this.options = this.toastr.toastrConfig;

		this.FAQSection = data.FAQSection
	}

	public isCollapsed = false;

	public beforeChange($event: NgbPanelChangeEvent) {

		if ($event.panelId === 'preventchange-2') {
			$event.preventDefault();
		}

		if ($event.panelId === 'preventchange-3' && $event.nextState === false) {
			$event.preventDefault();
		}
	}

	ngOnInit(): void {
		this.getFAQS()
	}

	cancel() {
		this.dialogRef.close({
			data: 'cancel'
		});
	}

	toggleTemplate(): void {

		if (this.loadingTemplate) {
			this.loadingTemplate = null;
		} else {
			this.loadingTemplate = this.customLoadingTemplate;
		}
	}

	closeDialog(data: any) {
		this.dialogRef.close({
			data: data
		});
	}

	async getFAQS() {

		var self = this

		try {
			self.authService.faqs(this.FAQSection)
				.then(async (res) => {
					let response = (typeof res.status_code !== 'undefined' ? res : res.error)
					if (typeof response.status_code !== 'undefined') {
						if (response.status_code == 200 && typeof response.data !== 'undefined') {

							self.FAQList = response.data
							self.loaded = 1
							return false;

						} else {
							let errorMessage = environment.config.customNotifications.generalMessages.error;
							response.errors.message.forEach(function (msg: string) {
								errorMessage = msg;
							})
							await self.notificationService.warningSwal(
								self.errorTitle, errorMessage, self.errorIcon
							);
							self.loaded = 1

							return false;
						}

					} else {
						// add sentry
						let errorMessage = environment.config.customNotifications.generalMessages.error;
						await self.notificationService.warningSwal(
							self.errorTitle, errorMessage, self.errorIcon
						);

						self.loaded = 1
						return false
					}
				})
				.catch(async err => {
					let errorMessage = environment.config.customNotifications.generalMessages.error;
					await self.notificationService.warningSwal(
						self.errorTitle, errorMessage, self.errorIcon
					);

					self.loaded = 1
					return false
				})

		} catch (err) {
			self.loaded = 1
			await self.notificationService.warningSwal(this.errorTitle, 'Am întâmpinat o problemă în procesarea informațiilor dvs. Vă rugăm să reîncercați sau să reveniți mai târziu.', this.errorIcon)
		}

	}
}
