<div [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme" (mouseover)="sidebarHoverMouseIn()"
	(mouseout)="sidebarHoverMouseOut()">
	<div class="app-header__logo">
		<!-- <div class="logo-src"></div> -->
		<div>
			<strong style="color: #0d7a50;">{{ !globals.toggleSidebar ? 'eSalubrizare5' : 'ES5' }}</strong>
			<!-- <strong *ngIf="!globals.toggleSidebar" style="color: #0d7a50">eSalubrizare5</strong>
			<img src="./assets/images/icon_cropped.png" alt="logo" *ngIf="globals.toggleSidebar" style="width: 50px;"> -->
		</div>
		<div class="header__pane ml-auto">
			<button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
				[ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar();">
				<span class="hamburger-box">
					<span class="hamburger-inner"></span>
				</span>
			</button>
		</div>
	</div>
	<div class="app-sidebar-content">
		<div class="app-sidebar-scroll">
			<perfect-scrollbar [autoPropagation]="true" class="scroll-container" [config]="config"
				[scrollIndicators]="true" style="max-width: 600px; max-height: 690px;">
				<div class="v-sidebar-menu vsm-default">
					<div class="vsm-list">

						<a routerLink="/dashboard/main" routerLinkActive="active-item" class="vsm-link">
							<i class="vsm-icon pe-7s-home"></i>
							<span class="vsm-title">Acasă</span>
						</a>

						<!-- <a routerLink="/solicitare-add" routerLinkActive="active-item" class="vsm-link">
							<i class="vsm-icon pe-7s-plus"></i>
							<span class="vsm-title">Solicită Contract</span>
						</a> -->

						<a routerLink="/solicitari" routerLinkActive="active-item" class="vsm-link">
							<i class="vsm-icon pe-7s-copy-file"></i>
							<span class="vsm-title">Solicitările mele</span>
						</a>

						<a routerLink="/sesizari" routerLinkActive="active-item" class="vsm-link">
							<i class="vsm-icon pe-7s-mail-open-file"></i>
							<span class="vsm-title">Sesizările mele</span>
						</a>

						<!-- <a routerLink="/contracte-active" routerLinkActive="active-item" class="vsm-link">
							<i class="vsm-icon pe-7s-copy-file"></i>
							<span class="vsm-title">Contractele mele</span>
						</a> -->

						<!-- <a routerLink="/plati" routerLinkActive="active-item" class="vsm-link">
							<i class="vsm-icon pe-7s-wallet"></i>
							<span class="vsm-title">Facturi și Plăți</span>
						</a> -->

						<a routerLink="/locatii" routerLinkActive="active-item" class="vsm-link">
							<i class="vsm-icon pe-7s-map-marker"></i>
							<span class="vsm-title">Locații</span>
						</a>

						<a routerLink="/utile" routerLinkActive="active-item" class="vsm-link">
							<i class="vsm-icon pe-7s-news-paper"></i>
							<span class="vsm-title">Utile</span>
						</a>

						<a routerLink="/setari" routerLinkActive="active-item" class="vsm-link">
							<i class="vsm-icon pe-7s-settings"></i>
							<span class="vsm-title">Setări</span>
						</a>

						<a routerLink="/notificari" routerLinkActive="active-item" class="vsm-link">
							<i class="vsm-icon pe-7s-bell"></i>
							<span class="vsm-title">Notificări</span>
						</a>

						<span class="vsm-link" (click)="logout()">
							<i class="vsm-icon pe-7s-power"></i>
							<span class="vsm-title">Deconectare</span>
						</span>
					</div>
				</div>
			</perfect-scrollbar>
		</div>
	</div>
</div>