import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, AbstractControl, FormGroup, FormGroupDirective, NgForm, Validators, ValidationErrors, ValidatorFn } from '@angular/forms';

import { environment } from '../../../environments/environment'

import { ErrorStateMatcher } from '@angular/material/core';

import { AuthService } from '../auth.service'

import { NotificationService } from '../../general/notification.service'

import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';

import { StorageService } from '../../general/storage.service'

import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

import { SuportOnlineComponent } from '../suport-online/suport-online.component'
import { FaqsComponent } from '../faqs/faqs.component'
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';

import { cloneDeep, random } from 'lodash-es';
import {
	GlobalConfig,
	ToastrService,
	ToastContainerDirective,
	ToastNoAnimation,
} from 'ngx-toastr';
import { C } from '@angular/cdk/keycodes';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const isSubmitted = form && form.submitted;
		return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
	}
}

function isValidCnpOrCui(control: AbstractControl) {
	const value = control.value;
	const valid = /^\d{4,13}$/.test(value);
	return valid ? null : { 'isValidCnpOrCui': true };
};

function isFiveDigits(control: AbstractControl) {
	const value = control.value;
	const valid = /^\d{5}$/.test(value);
	return valid ? null : { 'isFiveDigits': true };
};

export function passwordsMatchValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const group = control as FormGroup;
		const passwordControl = group.get('password');
		const passwordConfirmationControl = group.get('password_confirmation');

		if (passwordControl && passwordConfirmationControl) {

			const password = passwordControl.value;
			const passwordConfirmation = passwordConfirmationControl.value;

			if (password !== passwordConfirmation) {
				return { 'misMatch': true };
			}
			return null;
		}
		return null;
	};
}

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.sass'],
	providers: [
		{ provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false } }

	]
})
export class ForgotPasswordComponent implements OnInit {

	FAQSection: string = 'RECUPERARE_PAROLA_PAGE';
	lightBulb = faLightbulb

	env: any = environment

	// general error
	errorTitle: string = environment.config.customNotifications.headers.error
	errorIcon: string = environment.config.customNotifications.icons.error
	errorType: string = environment.config.customNotifications.icons.error
	// general success alert
	successTitle: string = environment.config.customNotifications.headers.success
	successIcon: string = environment.config.customNotifications.icons.success
	successType: string = environment.config.customNotifications.icons.success

	@ViewChild('ngxLoading') ngxLoadingComponent!: NgxLoadingComponent;
	@ViewChild('customLoadingTemplate') customLoadingTemplate!: TemplateRef<any>;
	public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
	public loading = false;
	public loadingTemplate!: TemplateRef<any> | null;
	matcher = new MyErrorStateMatcher();

	hide: boolean = true
	hide_confirmation: boolean = true

	private lastInserted: number[] = [];
	options: GlobalConfig;
	allowResendSMS: boolean = true
	token: string = ''

	formEmail: FormGroup;
	formCode: FormGroup;
	formPasswords: FormGroup;

	phone_no: string = ''

	isLinear = true;

	firstStep: boolean = true
	firstStepComplete: boolean = false
	successSteps: boolean = false

	disableRipple: boolean = true

	@ViewChild('stepper', { static: false }) private stepper!: MatStepper;

	constructor(
		private authService: AuthService,
		private notificationService: NotificationService,
		private localStorage: StorageService,
		public toastr: ToastrService,
		public dialog: MatDialog
	) {

		this.options = this.toastr.toastrConfig;

		this.formEmail = new FormGroup({
			email: new FormControl('', Validators.compose([
				Validators.required,
				Validators.maxLength(environment.config.validatorsAccrossApp.emailMaxLength),
				Validators.pattern(environment.config.emailRegex),
				Validators.email,
			])),
			cnp_cui: new FormControl('', Validators.compose([
				Validators.minLength(4),
				Validators.maxLength(13),
				Validators.required,
				isValidCnpOrCui
			]))
		});

		this.formCode = new FormGroup({
			code: new FormControl('', Validators.compose([
				Validators.required,
				Validators.minLength(5),
				Validators.maxLength(5),
				isFiveDigits
			]))
		})

		this.formPasswords = new FormGroup({
			password: new FormControl('', Validators.compose([
				Validators.required,
				Validators.minLength(environment.config.validatorsAccrossApp.minPassword),
				Validators.maxLength(environment.config.validatorsAccrossApp.maxPassword)
			])),
			password_confirmation: new FormControl('', Validators.compose([
				Validators.required,
				Validators.minLength(environment.config.validatorsAccrossApp.minPassword),
				Validators.maxLength(environment.config.validatorsAccrossApp.maxPassword),
			]))
		}, { validators: passwordsMatchValidator() });
	}

	get email() {
		return this.formEmail.get('email');
	}

	get cnp_cui() {
		return this.formEmail.get('cnp_cui');
	}

	get codeInput() {
		return this.formCode.get('code');
	}

	get password() {
		return this.formPasswords.get('password');
	}

	get password_confirmation() {
		return this.formPasswords.get('password_confirmation');
	}

	ngOnInit(): void {
	}

	goBack(index: number) {
		this.stepper.previous();
	}

	goForward(index: number) {

		var self = this
		switch (index) {

			case 1:
				return self.checkEmail()
				break;

			case 2:
				return self.confirmCode()
				break;

			case 3:
				return self.updatePwd()
				break;
		}
		// this.stepper.next();
	}

	async checkEmail() {
		var self = this
		if (!this.formEmail.valid) {
			self.loading = false
			await self.notificationService.warningSwal(this.errorTitle, 'Email-ul introdus nu este valid', this.errorIcon)
			return false;
		} else {
			this.authService.checkEmailPasswordReset(this.formEmail.value)
				.then(async (res) => {
					let response = (typeof res.status_code !== 'undefined' ? res : res.error)
					if (typeof response.status_code !== 'undefined') {
						if (response.status_code == 200 && typeof response.data !== 'undefined') {

							// success
							self.stepper.next()
							self.phone_no = response.data.phone

							self.loading = false
							return false;

						} else {
							let errorMessage = environment.config.customNotifications.generalMessages.error;
							response.errors.message.forEach(function (msg: string) {
								errorMessage = msg;
							})
							self.loading = false
							await self.notificationService.warningSwal(
								self.errorTitle, errorMessage, self.errorIcon
							);

							return false;
						}

					} else {
						// add sentry
						let errorMessage = environment.config.customNotifications.generalMessages.error;
						self.loading = false
						await self.notificationService.warningSwal(
							self.errorTitle, errorMessage, self.errorIcon
						);

						return false
					}
				})
				.catch(async err => {
					self.loading = false
					let errorMessage = environment.config.customNotifications.generalMessages.error;
					await self.notificationService.warningSwal(
						self.errorTitle, errorMessage, self.errorIcon
					);

					return false
				})
		}

	}

	async resendCode() {
		var self = this
		if (!this.formEmail.valid) {
			self.loading = false
			await self.notificationService.warningSwal(this.errorTitle, 'Email-ul introdus nu este valid', this.errorIcon)
			return false;
		} else {
			if (self.allowResendSMS) {

				self.authService.resendCodePasswordReset(self.formEmail.value)
					.then(async (res) => {
						let response = (typeof res.status_code !== 'undefined' ? res : res.error)
						if (typeof response.status_code !== 'undefined') {
							if (response.status_code == 200 && typeof response.data !== 'undefined') {

								// success
								self.stepper.next()
								self.phone_no = response.data.phone

								self.loading = false

								self.allowResendSMS = false
								let obj = {
									title: self.successTitle,
									message: 'Codul a fost retrimis prin SMS',
									type: self.successType
								}
								self.openToast(obj)

								setTimeout(() => {
									self.allowResendSMS = true
								}, 6000)

								return false;

							} else {
								let errorMessage = environment.config.customNotifications.generalMessages.error;
								response.errors.message.forEach(function (msg: string) {
									errorMessage = msg;
								})
								self.loading = false
								await self.notificationService.warningSwal(
									self.errorTitle, errorMessage, self.errorIcon
								);

								return false;
							}

						} else {
							// add sentry
							let errorMessage = environment.config.customNotifications.generalMessages.error;
							self.loading = false
							await self.notificationService.warningSwal(
								self.errorTitle, errorMessage, self.errorIcon
							);

							return false
						}
					})
					.catch(async err => {
						self.loading = false
						let errorMessage = environment.config.customNotifications.generalMessages.error;
						await self.notificationService.warningSwal(
							self.errorTitle, errorMessage, self.errorIcon
						);

						return false
					})

			} else {
				let obj = {
					title: self.errorTitle,
					message: 'Puteți trimite următorul cod prin sms în circa 1 minut',
					type: self.errorType
				}
				self.openToast(obj)
				return false
			}
		}

	}

	async confirmCode() {
		var self = this

		if (!this.formEmail.valid) {
			await self.notificationService.warningSwal(this.errorTitle, 'Email-ul sau CNP/CUI-ul introdus nu este valid', this.errorIcon)
			return false
		} else if (!this.formCode.valid) {
			await self.notificationService.warningSwal(this.errorTitle, 'Vă rugăm să completați codul primit prin sms', this.errorIcon)
			return false
		} else {
			// success here???
			self.authService.verifyCodePasswordReset({
				email: self.formEmail.value.email,
				cnp_cui: self.formEmail.value.cnp_cui,
				code: self.formCode.value.code
			})
				.then(async (res) => {
					let response = (typeof res.status_code !== 'undefined' ? res : res.error)
					if (typeof response.status_code !== 'undefined') {
						if (response.status_code == 200 && typeof response.data !== 'undefined' && typeof response.data.token !== 'undefined') {

							// success
							self.stepper.next()
							self.token = response.data.token
							self.firstStepComplete = true

							self.loading = false

							self.allowResendSMS = false
							let obj = {
								title: self.successTitle,
								message: 'Codul a fost confirmat cu succes. Vă rugăm să introduceți parola dorită',
								type: self.successType
							}
							self.openToast(obj)
							return false;

						} else {
							let errorMessage = environment.config.customNotifications.generalMessages.error;
							response.errors.message.forEach(function (msg: string) {
								errorMessage = msg;
							})
							self.loading = false
							await self.notificationService.warningSwal(
								self.errorTitle, errorMessage, self.errorIcon
							);

							return false;
						}

					} else {
						// add sentry
						let errorMessage = environment.config.customNotifications.generalMessages.error;
						self.loading = false
						await self.notificationService.warningSwal(
							self.errorTitle, errorMessage, self.errorIcon
						);

						return false
					}
				})
				.catch(async err => {
					self.loading = false
					let errorMessage = environment.config.customNotifications.generalMessages.error;
					await self.notificationService.warningSwal(
						self.errorTitle, errorMessage, self.errorIcon
					);

					return false
				})
		}
	}

	async updatePwd() {
		var self = this
		if (!this.formEmail.valid) {
			await self.notificationService.warningSwal(this.errorTitle, 'Email-ul sau CNP/CUI-ul introdus nu este valid', this.errorIcon)
			return false
		} else if (!this.formCode.valid) {
			await self.notificationService.warningSwal(this.errorTitle, 'Vă rugăm să completați codul primit prin sms', this.errorIcon)
			return false
		} else if (this.token == null || this.token == '') {
			await self.notificationService.warningSwal(this.errorTitle, 'Codul transmis nu este corect. Vă rugăm să reîncercați.', this.errorIcon)
			return false
		} else {
			// proceed to update passwords
			let dataObj = Object.assign({}, self.formPasswords.value,
				{ token: self.token, code: self.formCode.value.code, email: self.formEmail.value.email, cnp_cui: self.formEmail.value.cnp_cui })


			self.authService.updatePasswordReset(dataObj)
				.then(async (res) => {
					let response = (typeof res.status_code !== 'undefined' ? res : res.error)
					if (typeof response.status_code !== 'undefined') {
						if (response.status_code == 200 && typeof response.data !== 'undefined') {

							// success
							self.stepper.next()
							self.loading = false
							let obj = {
								title: self.successTitle,
								message: 'Parola a fost actualizată cu succes',
								type: self.successType
							}
							self.openToast(obj)
							return false;

						} else {
							let errorMessage = environment.config.customNotifications.generalMessages.error;
							response.errors.message.forEach(function (msg: string) {
								errorMessage = msg;
							})
							self.loading = false
							await self.notificationService.warningSwal(
								self.errorTitle, errorMessage, self.errorIcon
							);

							return false;
						}

					} else {
						// add sentry
						let errorMessage = environment.config.customNotifications.generalMessages.error;
						self.loading = false
						await self.notificationService.warningSwal(
							self.errorTitle, errorMessage, self.errorIcon
						);

						return false
					}
				})
				.catch(async err => {
					self.loading = false
					let errorMessage = environment.config.customNotifications.generalMessages.error;
					await self.notificationService.warningSwal(
						self.errorTitle, errorMessage, self.errorIcon
					);

					return false
				})
		}
	}

	openToast(object: any) {

		const { type, message, title } = object

		this.options.progressBar = true
		this.options.preventDuplicates = true
		this.options.closeButton = true

		// Clone current config so it doesn't change when ngModel updates
		const opt = cloneDeep(this.options);
		const inserted = this.toastr.show(
			message,
			title,
			opt,
			this.options.iconClasses[type]
		);
		if (inserted) {
			this.lastInserted.push(inserted.toastId);
		}
		return inserted;
	}

	openDialogFAQs() {

		var self = this
		console.log(this.FAQSection)
		let dialogRefCard = self.dialog.open(FaqsComponent, {
			disableClose: true,
			height: '90vh',
			width: '80vw',
			data: {
				FAQSection: self.FAQSection
			}
		})
	}

	openDialogSuportOnline() {

		var self = this

		let dialogRefCard = self.dialog.open(SuportOnlineComponent, {
			disableClose: true,
			height: '90vh',
			width: '80vw',
			data: {
				FAQSection: self.FAQSection
			}
		})

		dialogRefCard.afterClosed().subscribe(res => {
			const response = (typeof res.data !== 'undefined' && typeof res.data.id !== 'undefined' ? res.data : null)

			if (response !== null) {
				// show success stuff and reload data
				let message = 'Solicitarea dvs cu numărul ' + response.id + '/' + response.date + ' a fost trimisă cu succes.';
				this.notificationService.warningSwal(
					this.successTitle, message, this.successIcon
				);

			}
		})
	}
}
