import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseLayoutComponent } from './Layout/base-layout/base-layout.component';
import { PagesLayoutComponent } from './Layout/pages-layout/pages-layout.component';

// // Pages

import { ForgotPasswordComponent } from './auth-module/forgot-password/forgot-password.component';
import { ForgotPasswordBoxedComponent } from './DemoPages/UserPages/forgot-password-boxed/forgot-password-boxed.component';
import { LoginBoxedComponent } from './DemoPages/UserPages/login-boxed/login-boxed.component';
// import { LoginComponent } from './DemoPages/UserPages/login2/login.component';
import { RegisterBoxedComponent } from './DemoPages/UserPages/register-boxed/register-boxed.component';

// new methods

import { LoginComponent } from '../app/auth-module/login/login.component'

import { AuthGuard } from './guards/auth.guard'
import { LoginGuard } from './guards/login.guard'

const routes: Routes = [
	{
		path: '',
		component: PagesLayoutComponent,
		children: [
			// User Pages
			{
				path: 'pages/login',
				loadChildren: () => import('../app/auth-module/login/login.module').then(m => m.LoginModule)
			},
			{
				path: 'pages/login-boxed', component: LoginBoxedComponent, data: { extraParameter: '' }
			},
			{
				path: 'pages/register-boxed', component: RegisterBoxedComponent, data: { extraParameter: '' }
			},
			{
				path: 'pages/forgot-password', component: ForgotPasswordComponent, data: { extraParameter: '' }
			},
			{
				path: 'pages/forgot-password-boxed', component: ForgotPasswordBoxedComponent, data: { extraParameter: '' }
			},
			{
				path: '',
				redirectTo: 'pages/login',
				pathMatch: 'full'
			},
			// new methods, final stay
		]
	},

	// new section/menus
	{
		path: '',
		component: PagesLayoutComponent,
		children: [
			{
				path: 'auth',
				loadChildren: () => import('./auth-module/auth-module.module').then(m => m.AuthModuleModule),
				canActivate: [LoginGuard]
			}
		],
	},
	{
		path: '',
		component: BaseLayoutComponent,
		children: [
			{
				path: 'setari',
				loadChildren: () => import('./account/setari/setari.module').then(m => m.SetariModule),
				pathMatch: 'full',
				canActivate: [AuthGuard],
			},
			{
				path: 'dashboard/main',
				loadChildren: () => import('./dashboard/main/main.module').then(m => m.MainModule),
				pathMatch: 'full',
				canActivate: [AuthGuard],
			},
			{
				path: 'solicitare-add',
				loadChildren: () => import('./solicitari/add/add.module').then(m => m.AddModule),
				pathMatch: 'full',
				canActivate: [AuthGuard],
			},
			{
				path: 'solicitari',
				loadChildren: () => import('./solicitari/list/list.module').then(m => m.ListModule),
				pathMatch: 'full',
				canActivate: [AuthGuard],
			},
			{
				path: 'solicitare-view/:solicitareId',
				loadChildren: () => import('./solicitari/view/view.module').then(m => m.ViewModule),
				pathMatch: 'full',
				canActivate: [AuthGuard],
			},
			{
				path: 'contracte-active',
				loadChildren: () => import('./contracte/view-contracte/view-contracte.module').then(m => m.ViewContracteModule),
				pathMatch: 'full',
				canActivate: [AuthGuard],
			},
			{
				path: 'plati',
				loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule),
				pathMatch: 'full',
				canActivate: [AuthGuard],
			},
			{
				path: 'payments',
				loadChildren: () => import('./contracte/payment/payment.module').then(m => m.PaymentModule),
				pathMatch: 'full',
				canActivate: [AuthGuard],
			},
			{
				path: 'locatii',
				loadChildren: () => import('./locatii/locatii.module').then(m => m.LocatiiModule),
				pathMatch: 'full',
				canActivate: [AuthGuard],
			},
			{
				path: 'locatie/:id',
				loadChildren: () => import('./locatii/partials/view/view.module').then(m => m.ViewModule),
				pathMatch: 'full',
				canActivate: [AuthGuard],
			},
			{
				path: 'utile',
				loadChildren: () => import('./utile/utile.module').then(m => m.UtileModule),
				pathMatch: 'full',
				canActivate: [AuthGuard],
			},
			{
				path: 'utile/:id',
				loadChildren: () => import('./utile/partials/view/view.module').then(m => m.ViewModule),
				pathMatch: 'full',
				canActivate: [AuthGuard],
			},
			{
				path: 'notificari',
				loadChildren: () => import('./notificari/notificari.module').then(m => m.NotificariModule),
				pathMatch: 'full',
				canActivate: [AuthGuard],
			},
			{
				path: 'sesizari',
				loadChildren: () => import('./sesizari/sesizari.module').then(m => m.SesizariModule),
				pathMatch: 'full',
				canActivate: [AuthGuard],
			},
			{
				path: 'sesizare/:id',
				loadChildren: () => import('./sesizari/partials/view/view.module').then(m => m.ViewModule),
				pathMatch: 'full',
				canActivate: [AuthGuard],
			},
		]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes,
		{
			scrollPositionRestoration: 'enabled',
			anchorScrolling: 'enabled',
			relativeLinkResolution: 'legacy',
			useHash: true
		})],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
