<div class="app-header header-shadow bg-primary text-lighter d-flex align-items-center"
	(window:resize)="onResize($event)">
	<!-- <div class="app-header__content">
        <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
            [ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar();">
            <span class="hamburger-box">
                <span class="hamburger-inner"></span>
            </span>
        </button>
    </div> -->
	<!-- <div class="float-right text-right custom-header-class">
		<span class="float-right" style="color: white">{{user.nume}} {{user.prenume}}
			<span *ngIf="user.f_nume !== null && user.f_nume !== ''">({{user.f_nume}})</span>
		</span>
		<img src="/assets/images/icon_cropped.png" class=" float-right" style="max-height: 50px; width: 80px; top: 5px; right: 10px;" />
	</div> -->

	<div class="float-right custom-header-class h-60p">
		<a *ngIf="screenWidth > 330 && screenWidth < 768" routerLink="/setari"
			class="text-white m-0 mr-3 font-weight-bold text-decoration-none">{{user.nume | slice: 0:3
			}}{{user.nume.length > 3 ? '.' : ''}} {{user.prenume | slice: 0:3 }}{{user.prenume.length > 3 ? '.' : ''}}
			<span *ngIf="user.f_nume !== null && user.f_nume !== ''">, {{ user.f_nume | slice: 0:3 }} {{
				user.f_nume.length > 3 ? '.' : '' }}</span></a>

		<a *ngIf="screenWidth >= 768" routerLink="/setari"
			class="text-white m-0 mr-3 font-weight-bold text-decoration-none">{{user.nume | slice: 0:15
			}}{{user.nume.length > 15 ? '.' : ''}} {{user.prenume | slice: 0:15 }}{{user.prenume.length > 15 ? '.' :
			''}} <span *ngIf="user.f_nume !== null && user.f_nume !== ''">, {{ user.f_nume | slice: 0:30 }}{{
				user.f_nume.length > 30 ? '...' : '' }}</span></a>
		<img src="/assets/images/icon_cropped.png" class=" float-right" style="width: 100px;" />
	</div>

	<!-- <div class="logo-src"></div> -->
	<!-- <div class="app-header__content">
        <div class="app-header-left">
            <app-mega-menu></app-mega-menu>
        </div>
        <div class="app-header-right">
            <app-dots></app-dots>
            <app-user-box></app-user-box>
        </div>
    </div> -->
	<!-- <div class="header__pane ml-auto hide-mobile">
        <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
            [ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar();">
            <span class="hamburger-box">
                <span class="hamburger-inner"></span>
            </span>
        </button>
    </div> -->
	<div class="app-header__mobile-menu">
		<button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
			[ngClass]="{'is-active' : globals.toggleSidebarMobile}" (click)="toggleSidebarMobile()">
			<span class="hamburger-box">
				<span class="hamburger-inner"></span>
			</span>
		</button>
	</div>
	<!-- <div class="app-header__menu">
        <span>
            <button class="btn-icon btn-icon-only btn btn-primary"
                [ngClass]="{'is-active' : globals.toggleHeaderMobile}" (click)="toggleHeaderMobile()">
                <div class="btn-icon-wrapper">
                    <fa-icon [icon]="faEllipsisV"></fa-icon>
                </div>
            </button>
        </span>
    </div> -->
</div>