import { Component, OnInit, Inject, ViewChild, TemplateRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';

import { ErrorStateMatcher } from '@angular/material/core';

import { FormControl, AbstractControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';

import { environment } from '../../../environments/environment'
import { NotificationService } from '../../general/notification.service'

import * as _moment from 'moment';
const moment = _moment;

import { cloneDeep, random } from 'lodash-es';
import {
	GlobalConfig,
	ToastrService,
	ToastContainerDirective,
	ToastNoAnimation,
} from 'ngx-toastr';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const isSubmitted = form && form.submitted;
		return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
	}
}

import { AuthService } from '../auth.service'

function isNonNumeric(control: AbstractControl) {
	const value = control.value;
	const valid = /^[a-zA-ZăîâțșşțĂÎÂŢȘŞȚ\s]*$/.test(value);
	return valid ? null : { 'isNonNumeric': true };
}

function isTenDigits(control: AbstractControl) {
	const value = control.value;
	const valid = /^\d{10}$/.test(value);
	return valid ? null : { 'isTenDigits': true };
};

@Component({
	selector: 'app-suport-online',
	templateUrl: './suport-online.component.html',
	styleUrls: ['./suport-online.component.sass']
})
export class SuportOnlineComponent implements OnInit {

	options: GlobalConfig;

	// general error
	errorTitle: string = environment.config.customNotifications.headers.error
	errorIcon: string = environment.config.customNotifications.icons.error
	errorType: string = environment.config.customNotifications.icons.error
	// general success alert
	successTitle: string = environment.config.customNotifications.headers.success
	successIcon: string = environment.config.customNotifications.icons.success
	successType: string = environment.config.customNotifications.icons.success

	@ViewChild('ngxLoading') ngxLoadingComponent!: NgxLoadingComponent;
	@ViewChild('customLoadingTemplate') customLoadingTemplate!: TemplateRef<any>;
	public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
	public loading = false;
	public loadingTemplate!: TemplateRef<any> | null;
	matcher = new MyErrorStateMatcher();

	formData: any = FormGroup

	uploading: any = {
		documente_fotografii: false
	}

	files: any = {
		documente_fotografii: []
	}

	private lastInserted: number[] = [];

	constructor(
		private authService: AuthService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<SuportOnlineComponent>,
		private notificationService: NotificationService,
		public toastr: ToastrService,
	) {
		dialogRef.disableClose = true;
		this.options = this.toastr.toastrConfig;

		this.formData = new FormGroup({
			nume: new FormControl('', Validators.compose([
				Validators.minLength(environment.config.validatorsAccrossApp.minStringLength),
				Validators.maxLength(environment.config.validatorsAccrossApp.maxStringLength),
				isNonNumeric,
				Validators.required
			])),
			prenume: new FormControl('', Validators.compose([
				Validators.minLength(environment.config.validatorsAccrossApp.minStringLength),
				Validators.maxLength(environment.config.validatorsAccrossApp.maxStringLength),
				isNonNumeric,
				Validators.required
			])),
			telefon: new FormControl('', Validators.compose([
				Validators.minLength(10),
				Validators.maxLength(10),
				isTenDigits,
				Validators.required
			])),
			email: new FormControl('', Validators.compose([
				Validators.pattern(environment.config.emailRegex),
				Validators.maxLength(environment.config.validatorsAccrossApp.emailMaxLength),
				Validators.required,
				Validators.email,
			])),
			observatii: new FormControl('', Validators.compose([
				Validators.required,
			])),
			documente_fotografii_source: new FormControl('', []),
		});
	}

	ngOnInit(): void {
	}

	// form data
	get nume() {
		return this.formData.get('nume');
	}

	get prenume() {
		return this.formData.get('prenume');
	}

	get telefon() {
		return this.formData.get('telefon');
	}

	get email() {
		return this.formData.get('email');
	}

	get observatii() {
		return this.formData.get('observatii');
	}

	cancel() {
		this.dialogRef.close({
			data: 'cancel'
		});
	}

	toggleTemplate(): void {

		if (this.loadingTemplate) {
			this.loadingTemplate = null;
		} else {
			this.loadingTemplate = this.customLoadingTemplate;
		}
	}

	closeDialog(data: any) {
		this.dialogRef.close({
			data: data
		});
	}

	async submit() {
		var self = this

		this.toggleTemplate()
		this.loading = true

		const data = { ...this.formData.value, files: this.files }

		// prepare processing data pls        
		try {
			self.authService.suportOnline(data)
				.then(async (res) => {
					let response = (typeof res.status_code !== 'undefined' ? res : res.error)
					if (typeof response.status_code !== 'undefined') {
						if (response.status_code == 200 && typeof response.data !== 'undefined') {
							// make review and the amount to pay
							this.loading = false
							self.closeDialog(response.data)
							return false;
						} else {
							let errorMessage = environment.config.customNotifications.generalMessages.error;
							response.errors.message.forEach(function (msg: string) {
								errorMessage = msg;
							})
							await self.notificationService.warningSwal(
								self.errorTitle, errorMessage, self.errorIcon
							);
							this.loading = false

							return false;
						}

					} else {
						// add sentry
						let errorMessage = environment.config.customNotifications.generalMessages.error;
						await self.notificationService.warningSwal(
							self.errorTitle, errorMessage, self.errorIcon
						);

						this.loading = false
						return false
					}
				})
				.catch(async err => {
					let errorMessage = environment.config.customNotifications.generalMessages.error;
					await self.notificationService.warningSwal(
						self.errorTitle, errorMessage, self.errorIcon
					);
					this.loading = false
					return false
				})

		} catch (err) {
			this.loading = false
			await self.notificationService.warningSwal(this.errorTitle, 'Am întâmpinat o problemă în procesarea informațiilor dvs. Vă rugăm să reîncercați sau să reveniți mai târziu.', this.errorIcon)
		}
	}

	async upload(files: any, key: string) {
		var self = this
		const file = files[0];
		const formData = new FormData();
		formData.append('file', file, file.name);

		this.uploading[key] = true;
		// self.files[key] = []

		return this.authService.fileUpload(formData)
			.then(async (result) => {

				let response = (typeof result.status_code !== 'undefined' ? result : result.error)
				if (typeof response.status_code !== 'undefined') {
					if (response.status_code == 200 && typeof response.data !== 'undefined') {
						// make review and the amount to pay
						self.files[key].push(result.data)
						self.formData.get(key + '_source').setValue(JSON.stringify(self.files[key]));

						self.uploading[key] = false;

						let obj = {
							title: self.successTitle,
							message: 'Fișierul dvs a fost adăugat cu succes.',
							type: self.successIcon
						}
						self.openToast(obj)
						return false;

					} else {
						let errorMessage = environment.config.customNotifications.generalMessages.fileErrorMsg;
						response.errors.message.forEach(function (msg: string) {
							errorMessage = msg;
						})
						await self.notificationService.warningSwal(
							self.errorTitle, errorMessage, self.errorIcon
						);
						this.loading = false
						self.uploading[key] = false;
						return false;
					}

				} else {
					// add sentry
					let errorMessage = environment.config.customNotifications.generalMessages.fileErrorMsg;
					await self.notificationService.warningSwal(
						self.errorTitle, errorMessage, self.errorIcon
					);

					this.loading = false
					self.uploading[key] = false;
					return false
				}

			})
			.catch((err) => {
				self.uploading[key] = false;
				// show error.. on toast

				let obj = {
					title: self.errorTitle,
					message: environment.config.customNotifications.generalMessages.fileErrorMsg,
					type: self.errorIcon
				}
				self.openToast(obj)
			});
	}

	openToast(object: any) {

		const { type, message, title } = object

		this.options.progressBar = true
		this.options.preventDuplicates = true
		this.options.closeButton = true

		// Clone current config so it doesn't change when ngModel updates
		const opt = cloneDeep(this.options);
		const inserted = this.toastr.show(
			message,
			title,
			opt,
			this.options.iconClasses[type]
		);
		if (inserted) {
			this.lastInserted.push(inserted.toastId);
		}
		return inserted;
	}

	fileChangeEvent(ev: any) {
		console.log(ev, 'check here pls')
	}

	clearInputFile(key: string) {

		let obj: any = {}
		obj[key + '_source'] = ''
		this.formData.patchValue(obj)
		this.files[key] = []
	}

	removeFile(key: string, index: number) {
		this.files[key].splice(index, 1)
		var self = this
		self.formData.get(key + '_source').setValue(JSON.stringify(self.files[key]));
	}
}
