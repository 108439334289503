<div class="min-h-100 bg-animation forgotPasswordPageClass custom-bg">
	<div class="d-flex justify-content-center align-items-center">
		<div class="mx-auto app-login-box col-md-6">
			<div class="logo text-center">
				<img src="/assets/images/icon.png" class="img-fluid example-full-width" />
			</div>
			<div class="modal-dialog w-100 ">
				<div class="modal-content">
					<div class="modal-header">
						<div class="h5 modal-title">
							Recuperare parolă
							<h6 class="mt-1 mb-0 opacity-8"><span>Pentru a recupera parola, vă rugăm să urmariți pașii
									de mai jos.</span></h6>
						</div>
					</div>
					<div class="modal-body">
						<mat-vertical-stepper orientation="vertical" [linear]="isLinear" #stepper="matVerticalStepper"
							[disableRipple]="disableRipple">
							<mat-step [stepControl]="formEmail" [completed]="firstStepComplete">
								<form [formGroup]="formEmail">
									<ng-template matStepLabel>Pasul 1</ng-template>
									<p>Completați adresa de E-mail</p>

									<mat-form-field class="example-full-width mt-3 mb-3">
										<input formControlName="email" matInput placeholder="Email"
											[errorStateMatcher]="matcher" type="email">

										<mat-error class="p-2"
											*ngIf="(email?.dirty || email?.touched) && email?.errors?.required">
											<strong>Câmp obligatoriu</strong>
										</mat-error>

										<mat-error class="p-2"
											*ngIf="(email?.dirty || email?.touched) && email?.errors?.maxlength">
											<strong>Maxim 50 de caractere</strong>
										</mat-error>

										<mat-error class="p-2"
											*ngIf="(email?.dirty || email?.touched) && (!email?.errors?.minlength && !email?.errors?.maxlength && email?.errors?.email || email?.errors?.pattern)">
											<strong>Email invalid</strong>
										</mat-error>

									</mat-form-field>

									<mat-form-field class="example-full-width mt-3 mb-3">
										<input formControlName="cnp_cui" matInput placeholder="CNP | CUI"
											[errorStateMatcher]="matcher" type="text">

										<mat-error class="p-2"
											*ngIf="(cnp_cui?.dirty || cnp_cui?.touched) && cnp_cui?.errors?.required">
											<strong>Câmp obligatoriu</strong>
										</mat-error>

										<mat-error class="p-2"
											*ngIf="(cnp_cui?.dirty || cnp_cui?.touched) && cnp_cui?.errors?.minlength">
											<strong>Minim 4 caractere</strong>
										</mat-error>

										<mat-error class="p-2"
											*ngIf="(cnp_cui?.dirty || cnp_cui?.touched) && cnp_cui?.errors?.maxlength">
											<strong>Maxim 13 caractere</strong>
										</mat-error>

										<mat-error class="p-2"
											*ngIf="!cnp_cui?.errors?.required && !cnp_cui?.errors?.minlength && !cnp_cui?.errors?.maxlength && cnp_cui?.errors?.isValidCnpOrCui">
											<strong>CNP | CUI trebuie să conțină între 4 și 13 cifre</strong>
										</mat-error>

									</mat-form-field>
									<div>
										<button mat-button (click)="goForward(1)" class="float-right mt-3"
											[disabled]="!formEmail.valid">Următorul pas</button>
									</div>
								</form>
							</mat-step>
							<mat-step [stepControl]="formCode" [completed]="firstStepComplete">
								<form [formGroup]="formCode">
									<ng-template matStepLabel>Pasul 2</ng-template>
									<p class="text-center">Introduceți codul primit prin SMS la numărul de telefon
										<strong>{{phone_no}} </strong>
									</p>
									<mat-form-field class="example-full-width mt-3 mb-3">
										<mat-label>Cod SMS</mat-label>
										<input matInput formControlName="code" [errorStateMatcher]="matcher" />

										<mat-error class="p-2"
											*ngIf="(codeInput?.dirty || codeInput?.touched) && codeInput?.errors?.required">
											<strong>Câmp obligatoriu</strong>
										</mat-error>

										<mat-error class="p-2"
											*ngIf="(codeInput?.dirty || codeInput?.touched) && codeInput?.errors?.minlength">
											<strong>Obligatoriu 5 caractere</strong>
										</mat-error>

										<mat-error class="p-2"
											*ngIf="(codeInput?.dirty || codeInput?.touched) && codeInput?.errors?.maxlength">
											<strong>Obligatoriu 5 caractere</strong>
										</mat-error>

										<mat-error class="p-2"
											*ngIf="!codeInput?.errors?.required && !codeInput?.errors?.minlength && !codeInput?.errors?.maxlength && codeInput?.errors?.isFiveDigits">
											<strong>Codul SMS trebuie să aibă exact 5 cifre</strong>
										</mat-error>
									</mat-form-field>

									<div class="divider"></div>
									<div>
										<p class="text-center red-text pointer" (click)="resendCode()">Nu ați primit
											codul? Retrimitere SMS aici.</p>
										<p class="text-center primary-text">Codul de activare este valabil 1h.</p>
									</div>
									<div class="divider"></div>
									<div>
										<button mat-button (click)="goBack(2)" type="button">Inapoi</button>
										<button mat-button (click)="goForward(2)" type="button" class="float-right"
											[disabled]="!formCode.valid">Următorul pas</button>
									</div>
								</form>
							</mat-step>
							<mat-step [stepControl]="formPasswords">
								<form [formGroup]="formPasswords">
									<ng-template matStepLabel>Pasul 3</ng-template>
									<p>Introduceți parola dorită</p>
									<mat-form-field class="example-full-width mt-3 mb-3">
										<input formControlName="password" matInput placeholder="Parolă"
											[errorStateMatcher]="matcher" required="required"
											[type]="hide ? 'password' : 'text'" (paste)="false">

										<mat-icon matSuffix (click)="hide = !hide">
											{{hide ? 'visibility_off' : 'visibility'}}
										</mat-icon>

										<mat-error class="p-2"
											*ngIf="(password?.dirty || password?.touched) && password?.errors?.required">
											<strong>Câmp obligatoriu</strong>
										</mat-error>
										<mat-error class="p-2"
											*ngIf="(password?.dirty || password?.touched) && password?.errors?.minlength">
											<strong>Minim 8 caractere</strong>
										</mat-error>

										<mat-error class="p-2"
											*ngIf="(password?.dirty || password?.touched) && password?.errors?.maxlength">
											<strong>Maxim 50 caractere</strong>
										</mat-error>
									</mat-form-field>

									<mat-form-field class="example-full-width mt-3 mb-3">
										<input formControlName="password_confirmation" matInput
											placeholder="Confirmați parola" required="required"
											[type]="hide_confirmation ? 'password' : 'text'"
											[errorStateMatcher]="matcher" (paste)="false">

										<mat-icon matSuffix (click)="hide_confirmation = !hide_confirmation">
											{{hide_confirmation ? 'visibility_off' : 'visibility'}}
										</mat-icon>

										<mat-error class="p-2"
											*ngIf="(password_confirmation?.dirty || password_confirmation?.touched) && password_confirmation?.errors?.required">
											<strong>Câmp obligatoriu</strong>
										</mat-error>
										<mat-error class="p-2"
											*ngIf="(password_confirmation?.dirty || password_confirmation?.touched) && password_confirmation?.errors?.minlength">
											<strong>Minim 8 caractere</strong>
										</mat-error>

										<mat-error class="p-2"
											*ngIf="(password_confirmation?.dirty || password_confirmation?.touched) && password_confirmation?.errors?.maxlength">
											<strong>Maxim 50 caractere</strong>
										</mat-error>
									</mat-form-field>

									<mat-error
										*ngIf="password?.valid && password_confirmation?.valid && formPasswords?.errors?.misMatch"
										class="p-2">
										<small>
											<strong>
												Parolele introduse nu coincid
											</strong>
										</small>
									</mat-error>

									<div>
										<button mat-button (click)="goForward(3)" class="float-right"
											[disabled]="!formPasswords.valid">Următorul pas</button>
									</div>
								</form>
							</mat-step>
							<mat-step [stepControl]="formPasswords" [completed]="formPasswords.valid && successSteps">
								<ng-template matStepLabel>Succes</ng-template>
								<h4 class="green-text mt-3">Parolă actualizată cu succes</h4>
							</mat-step>
						</mat-vertical-stepper>

						<div class="divider"></div>

						<button type="button" (click)="openDialogFAQs()"
							class=" mt-3 mb-3 btn-transition btn-block btn-sm btn btn-outline-primary">
							<fa-icon [icon]="lightBulb"></fa-icon> ÎNTREBĂRI FRECVENTE
						</button>

						<div class="divider"></div>

						<button type="button" (click)="openDialogSuportOnline()"
							class=" mt-3 mb-3 btn-transition btn-block btn-sm btn btn-outline-primary">
							SUPORT ONLINE
						</button>

					</div>
					<div class="modal-footer clearfix">
						<h6 class="mb-0">
							<a routerLink="/auth/login" class="text-primary">Autentificare cont</a>
						</h6>
					</div>
				</div>
			</div>
			<div class="text-center text-white opacity-8 mt-3">
				{{env.config.copyrightText}}
			</div>
		</div>
	</div>
</div>

<div class="" *ngIf="loading">
	<ngx-loading [show]="loading"
		[config]="{backdropBorderRadius: '3px', backdropBackgroundColour: 'rgba(0, 0, 0, 0.5)'}"
		[template]="loadingTemplate!"></ngx-loading>
	<h4 class="loading-text">Vă rugăm așteptați!</h4>
</div>