<div class="app-footer">
	<div class="app-footer__inner">
		<div class="app-footer-left">
			<!-- <app-footer-dots></app-footer-dots> -->
			<small>{{env.config.copyrightText}}</small>
		</div>
		<!-- <div class="app-footer-right">
      <app-footer-menu></app-footer-menu>
    </div> -->
	</div>
</div>